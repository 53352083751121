import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  inject,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { Router, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { TUI_IS_MOBILE } from '@taiga-ui/cdk';
import { ConditionsComponent } from 'src/app/features/about/conditions/conditions.component';
import { HelpComponent } from 'src/app/features/about/help/help.component';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-menu',
  templateUrl:"menu.component.html",
  imports: [CommonModule, MatListModule, RouterModule, TranslateModule],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent {
  auth = inject(AuthService);
  router = inject(Router);
  dialog = inject(MatDialog);
window= window

  constructor(@Inject(TUI_IS_MOBILE) readonly isMobile: boolean) {}

  async signOut() {
    await this.auth.logout();
  }

  openConditions() {
    this.dialog.open(ConditionsComponent, {
      width: this.isMobile ? '95%' : '60%',
      height: '90%',
      panelClass: 'zendy-modal',
    });
  }

  openHelp() {
    this.dialog.open(HelpComponent, {
      width: this.isMobile ? '95%' : '60%',
      height: '90%',
      panelClass: 'zendy-modal',
    });
  }
}
