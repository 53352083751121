<div class="p-4">
  <h3 class="text-center">
    {{ 'COMPONENTS.PROFILE.MODAL.TITLE' | translate }}
  </h3>
  <p class="text-center mt-6">{{ 'COMPONENTS.PROFILE.MODAL.DESC' | translate }}</p>
  <hr class="my-6" />
  <div class="flex justify-between gap-4">
    <app-button expand="full" color="secondary" (click)="close()">{{
      'COMMON.CANCEL' | translate
    }}</app-button>
    <app-button expand="full" (click)="confirm()">
      {{'COMPONENTS.PROFILE.BUTTON' | translate }}
    </app-button>
  </div>
</div>
