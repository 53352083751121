// spinner.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SpinnerService {
  private spinnerState = new BehaviorSubject(false); // initial state: false (spinner hidden)

  showSpinner(): void {
    this.spinnerState.next(true);
  }

  hideSpinner(): void {
    this.spinnerState.next(false);
  }

  getSpinnerState(): Observable<boolean> {
    return this.spinnerState.asObservable();
  }
}
