<h2 class="!mt-0">
  {{ 'COMPONENTS.PROFILE.MY_PROFILE.EDIT_PROFILE.TITLE' | translate }}

  {{ !name || name == ' ' || name == undefined ? user.attributes?.['phone_number'] : name + ' ' + middle_name }}
</h2>

<form [formGroup]="createForm">

  <!-- Estructura dinámica basada en isMobile -->
  <div *ngIf="!isMobile" class="grid grid-cols-2 gap-4">
    <!-- Campos para pantallas más grandes (dos columnas) -->

    <!-- Nombre -->
    <mat-form-field class="w-full">
      <mat-label>
        {{ 'COMPONENTS.PROFILE.MY_PROFILE.EDIT_PROFILE.NAME' | translate }}
      </mat-label>
      <input formControlName="name"
             type="text"
             matInput
             placeholder="{{ 'COMPONENTS.PROFILE.MY_PROFILE.EDIT_PROFILE.NAME' | translate }}" />
    </mat-form-field>

    <!-- Apellido -->
    <mat-form-field class="w-full">
      <mat-label>
        {{ 'COMPONENTS.PROFILE.MY_PROFILE.EDIT_PROFILE.SURNAME' | translate }}
      </mat-label>
      <input formControlName="middle_name"
             type="text"
             matInput
             placeholder="{{ 'COMPONENTS.PROFILE.MY_PROFILE.EDIT_PROFILE.SURNAME' | translate }}" />
    </mat-form-field>

    <!-- Dirección -->
    <mat-form-field class="w-full">
      <mat-label>
        {{ 'COMPONENTS.PROFILE.MY_PROFILE.EDIT_PROFILE.ADDRESS' | translate }}
      </mat-label>
      <input formControlName="custom:address"
             type="text"
             matInput
             placeholder="{{ 'COMPONENTS.PROFILE.MY_PROFILE.EDIT_PROFILE.ADDRESS' | translate }}" />
    </mat-form-field>

    <!-- Ciudad -->
    <mat-form-field class="w-full">
      <mat-label>
        {{ 'COMPONENTS.PROFILE.MY_PROFILE.EDIT_PROFILE.CITY' | translate }}
      </mat-label>
      <input formControlName="custom:ciudad"
             type="text"
             matInput
             placeholder="{{ 'COMPONENTS.PROFILE.MY_PROFILE.EDIT_PROFILE.CITY' | translate }}" />
    </mat-form-field>

    <!-- Código Postal -->
    <mat-form-field class="w-full">
      <mat-label>
        {{ 'COMPONENTS.PROFILE.MY_PROFILE.EDIT_PROFILE.ZIPCODE' | translate }}
      </mat-label>
      <input formControlName="custom:postcode"
             type="text"
             matInput
             placeholder="{{ 'COMPONENTS.PROFILE.MY_PROFILE.EDIT_PROFILE.ZIPCODE' | translate }}" />
    </mat-form-field>

    <!-- País -->
    <mat-form-field class="w-full">
      <mat-label>
        {{ 'COMPONENTS.PROFILE.MY_PROFILE.EDIT_PROFILE.COUNTRY' | translate }}
      </mat-label>
      <input formControlName="custom:country"
             type="text"
             matInput
             placeholder="{{ 'COMPONENTS.PROFILE.MY_PROFILE.EDIT_PROFILE.COUNTRY' | translate }}" />
    </mat-form-field>

    <!-- ID -->
    <mat-form-field class="w-full">
      <mat-label>
        {{ 'COMPONENTS.PROFILE.MY_PROFILE.EDIT_PROFILE.ID' | translate }}
      </mat-label>
      <input formControlName="custom:passport"
             type="text"
             matInput
             placeholder="{{ 'COMPONENTS.PROFILE.MY_PROFILE.EDIT_PROFILE.ID' | translate }}" />
    </mat-form-field>

    <!-- Correo Electrónico -->
    <mat-form-field class="w-full">
      <mat-label>
        {{ 'COMPONENTS.PROFILE.MY_PROFILE.EDIT_PROFILE.EMAIL' | translate }}
      </mat-label>
      <input type="email"
             formControlName="email"
             matInput
             placeholder="example@example.com" />
    </mat-form-field>

    <!-- Teléfono -->
    <mat-form-field class="w-full">
      <mat-label>
        {{ 'COMPONENTS.PROFILE.MY_PROFILE.EDIT_PROFILE.PHONE' | translate }}
      </mat-label>
      <input type="tel"
             formControlName="phone_number"
             matInput
             placeholder="+34555555123" />
    </mat-form-field>

  </div>

  <div *ngIf="isMobile" class="flex flex-col gap-4">
    <!-- Campos para pantallas pequeñas (una columna) -->

    <mat-form-field class="w-full" *ngFor="let field of [
      { label: 'NAME', control: 'name' },
      { label: 'SURNAME', control: 'middle_name' },
      { label: 'ADDRESS', control: 'custom:address' },
      { label: 'CITY', control: 'custom:ciudad' },
      { label: 'ZIPCODE', control: 'custom:postcode' },
      { label: 'COUNTRY', control: 'custom:country' },
      { label: 'ID', control: 'custom:passport' },
      { label: 'EMAIL', control: 'email' },
      { label: 'PHONE', control: 'phone_number' }
    ]">
      <mat-label>
        {{ ('COMPONENTS.PROFILE.MY_PROFILE.EDIT_PROFILE.' + field.label) | translate }}
      </mat-label>
      <input [formControlName]="field.control" matInput />
    </mat-form-field>

  </div>

   <!-- Botones -->
  <div class="flex justify-between gap-4 mt-12">
    <app-button expand="full" color="secondary" (click)="goBack()">
      {{ 'COMMON.CANCEL' | translate }}
    </app-button>
    <app-button expand="full" (click)="onUpdate()">
      {{ 'COMMON.CONFIRM' | translate }}
    </app-button>
  </div>

</form>

<style>
  .grid {
  display: grid;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, 1fr);
}

.gap-4 {
  gap: 16px;
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.w-full {
  width: 100%;
}
</style>
