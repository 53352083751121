import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ButtonModule } from '../../button/button.module';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-profile',
  template: `
    <div class="w-full max-w-sm">
      <div class="flex flex-col items-center gap-y-2">
        <img
          class="flex w-24 h-24 rounded-full"
          src="./assets/icons/profile-icon.svg"
          alt="Profile image"
        />
        <h2 class="flex text-white m-0" *ngIf="authStatus$ | async as user">
          {{ !user.name ? user.phone_number: user.name + ' ' + user.middle_name }}
        </h2>

        <div class="flex">
          <app-button [customClass]="'menu-profile-button'" color="secondary" size="small" routerLink="/profile">
            <span class="text-gray">
              {{ 'COMPONENTS.PROFILE.BUTTON' | translate }}
            </span>
          </app-button>
        </div>
      </div>
    </div>
  `,
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ButtonModule,
    TranslateModule,
  ],
  styles: [
    `
      app-balance {
        img {
          width: 147px !important;
        }
      }
    `,
  ],
})
export class ProfileComponent {
  authStatus$ = inject(AuthService).authStatus$;
}
