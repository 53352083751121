<div
  *ngIf="contact"
  class="p-4 bg-gray-opacity2 rounded-2xl mt-8 flex items-center justify-between flex-col"
>
  <img
    width="96px"
    class="rounded-full"
    src="./assets/icons/profile-icon.svg"
  />
  <div class="w-full flex flex-col">
    <div class="flex items-center flex-col gap-2 mt-8 mb-8">
      <h2 class="m-0 tracking-tight text-gray font-semibold">
        {{ contact.name }}
      </h2>
      <div class="text-gray text-base">
        {{ contact.telephone }}
      </div>
      <div class="text-gray text-base">
        {{ contact.email }}
      </div>
    </div>
  </div>
</div>
<div *ngIf="contact" class="flex flex-row mt-8 mb-8 w-full justify-center gap-16">
  <div class="flex flex-col items-center gap-2">
    <a [routerLink]="'/calls/dial/' + contact.telephone + '/' + contact.name" replaceUrl="true">
      <img src="./assets/icons/new-call.svg" />
    </a>
    <div class="text-gray text-base text-center">{{ 'CONTACTS.DETAILS.CALL' | translate }}</div>
  </div>
  <div class="flex flex-col items-center gap-2">
    <a [routerLink]="'/contacts/edit-contact/' + contact.id" replaceUrl="true">
      <img src="./assets/icons/edit-profile.svg" />
    </a>
    <div class="text-gray text-base text-center">{{ 'COMMON.EDIT' | translate }}</div>
  </div>
</div>
<!-- <div *ngIf="transfers?.items">
  <h2>{{ 'CONTACTS.DETAILS.TRANSFERS_TITLE' | translate }}</h2>
  <mat-list>
    <mat-list-item
      class="border-b border-solid border-gray-opacity !h-16"
      *ngFor="let item of transfers.items"
    >
      <div class="flex justify-between items-center" matListItemTitle>
        <div class="text-base">
          {{ item?.createdAt | date : 'dd/MM/yyyy' }}
        </div>
        <div class="flex items-end text-base gap-1">
          <img
            [src]="
              item?.resource == 'recharge'
                ? './assets/icons/transfer-add.svg'
                : './assets/icons/transfer-minus.svg'
            "
          />
          {{ item?.amount | currency : item?.currency : true }}
        </div>
      </div>
    </mat-list-item>
  </mat-list>
  <div class="warning" *ngIf="transfers.items.length <= 0">
    <div class="flex justify-center">
      <img class="mb-2 w-40 md:w-28" src="./assets/icons/no-records.svg" />
    </div>
    <h2>{{ 'CONTACTS.DETAILS.NO_RESULTS_TITLE' | translate }}</h2>
    <p class="text-base mb-8">
      {{ 'CONTACTS.DETAILS.NO_RESULTS_DESC' | translate }}
    </p>
  </div>
</div> -->
<div *ngIf="calls && calls.length > 0">
  <h2>{{ 'CONTACTS.DETAILS.CALLS_TITLE' | translate }}</h2>
  <mat-list>
    <mat-list-item
      class="border-b border-solid border-gray-opacity !h-16"
      *ngFor="let item of calls"
    >
      <div class="flex justify-between items-center" matListItemTitle>
        <div class="text-base">
          {{ item?.createdAt | date : 'dd/MM/yyyy' }}
        </div>
        <div class="flex flex-col items-end">
          <div class="flex items-center gap-2 text-base">
            <!-- Mostramos el tiempo formateado directamente -->
            {{ item?.formattedLabelTime }}
          </div>
        </div>
      </div>
    </mat-list-item>
  </mat-list>
  <div class="warning" *ngIf="calls.length <= 0">
    <div class="flex justify-center">
      <img class="mb-2 w-40 md:w-28" src="./assets/icons/no-records.svg" />
    </div>
    <h2>{{ 'CONTACTS.DETAILS.NO_RESULTS_TITLE' | translate }}</h2>
    <p class="text-base mb-8">
      {{ 'CONTACTS.DETAILS.NO_RESULTS_DESC' | translate }}
    </p>
  </div>
</div>



