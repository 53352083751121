<div class="container mx-auto p-0">
  <div class="no-gap-grid grid grid-cols-1 md:grid-cols-2 searcher" [hidden]="!showSearchEsim">
    <!-- Div izquierda -->
    <div class="image-container">
      <img alt="Woman in red shirt talking on phone" class="h-full w-full block object-cover"
           src="./assets/icons/esim-header.png" />
    </div>

    <!-- Div derecha -->
    <div class="content-container bg-red-700 text-white p-6 flex flex-col justify-center">
      <div class="overlay" *ngIf="isLoading">
        <div class="loader-popup">
          <!-- Contenido del loader, como un spinner o mensaje de carga -->
          <div class="loader"></div>
          <p>Cargando...</p>
        </div>
      </div>
      <p class="text-xl font-bold">
        {{ 'ESIM.SEARCH_TITLE_1' | translate }}
      </p>
      <p class="mt-2 text-sm item-value mb-4">
        {{ 'ESIM.SEARCH_TITLE_2' | translate }}
      </p>

      <div class="select-container mb-4">
        <mat-form-field appearance="fill" class="w-full">
          <mat-select [(ngModel)]="selectedType" class="w-full p-2 rounded bg-white text-black custom-select" (selectionChange)="onTypeChange()">

            <!-- Opción predeterminada según el idioma -->
            <mat-option [value]="'default'" disabled>{{ 'COMMON.SELECT' | translate }}</mat-option>

            <!-- Opciones de la lista -->
            <mat-option *ngFor="let type of types" [value]="type">{{ type }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="select-container mb-4">
        <mat-form-field appearance="fill" class="w-full">
          <mat-select [(ngModel)]="selectedCountry" class="w-full p-2 rounded bg-white text-black" [disabled]="isDisabled" >

            <input (keyup)="onKey($event)" placeholder="{{ 'ESIM.COUNTRY.PLACEHOLDER' | translate }}" class="w-full p-2 rounded bg-white text-black">

            <mat-option value="" class="!hidden">{{ 'ESIM.SELECT_COUNTRY' | translate }}</mat-option>

            <mat-option *ngFor="let country of filteredCountries" [value]="country.code">
              <div class="flex items-center">
                <img [src]="country.image" alt="{{ country.country }}" class="country-flag mr-2 w-6 h-4">
                <span>{{ country.country }}</span>
              </div>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="button-container mt-2">
        <button mat-raised-button class="bg-white text-red-700" (click)="loadEsimPackages()" [disabled]="isDisabled">
          {{ 'ESIM.BTN_SEARCH_ESIM' | translate }}
        </button>
        <button mat-raised-button class="bg-white text-red-700 ml-4" (click)="showMyEsims()">
          {{ 'ESIM.BTN_MY_ESIM' | translate }}
        </button>
      </div>

    </div>
  </div>
</div>

<!-- Mostrar múltiples tarjetas si hay más de una -->
<div *ngIf="!showEsims && filteredPackages.length > 0" class="grid my-6 !gap-2">
  <h2 class="text-xl font-bold text-center">
    <mat-icon (click)="toogleEsimSearcher()" class="float-left ms-4 cursor-pointer">arrow_back_ios</mat-icon>{{ countryName | titlecase }}
  </h2>
</div>

  <div *ngIf="!showEsims && filteredPackages.length > 0" class="grid grid-cols-2 my-4 !gap-0">
    <div class="sims-tab m-0 pb-4 cursor-pointer" [ngClass]="sim_status_data ? 'active' : ''" (click)="toggleClassData()">{{ "ESIM.DATA" | translate }}</div>
    <div class="sims-tab m-0 pb-4 cursor-pointer" [hidden]="dataVoiceEsims" [ngClass]="sim_status_data_voice ? 'active' : ''" (click)="toggleClassDataVoice()">{{ "ESIM.DATA-VOICE-TEXT" | translate }}</div>
  </div>

  <div *ngIf="!showEsims && filteredPackages.length > 0" class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 mt-4">

    <mat-card *ngFor="let package of filteredPackages" class="esim-card {{ package.operador[0].plan_type }} {{ filteredPackages.length > 1 ? '' : 'esim-card-single' }}" [ngClass]="package.operador[0].plan_type == 'data' ? esim_data_visible : esim_data_voice_visible" appearance="outlined">
      <mat-card-header class="flex items-start">
        <div class="header-image-container !mr-4">
          <img mat-card-image [src]="package.operador[0].image" alt="{{ package.operador[0].operador }}"
               class="sim-card-image-small">
          <span class="country-label">{{ capitalizeCountryName(package.country) }}</span>
        </div>
        <div>
          <h2 class="text-black m-0 text-xl">{{ package.title }}</h2>
          <h2 class="m-0 text-xl">{{ package.price | currency:'EUR':'symbol':'1.2-2':'es-ES' }}</h2>
          <div class="flex" style="height:fit-content;">
            <div *ngFor="let item of package.operador[0].plan_type.split('-')">
              <img width="16" [src]="'./assets/icons/' + item + '_ico.svg'" title="{{ item }}" class="me-1" />
            </div>
          </div>
        </div>
      </mat-card-header>

      <hr>

      <mat-card-content>
        <div class="item-detail flex items-center mb-1">
          <mat-icon class="text-red-700">public</mat-icon>
          <label class="item-label font-bold mx-1">{{ 'ESIM.COVERAGE' | translate }}: </label>
          <span class="item-value">
            {{ package.country | titlecase }}
          </span>
        </div>
        <div class="item-detail flex items-center my-1">
          <mat-icon class="text-red-700">swap_horiz</mat-icon>
          <label class="item-label font-bold mx-1">{{ 'ESIM.DATA' | translate }}: </label>
          <span class="item-value">
            {{ package.data }}
          </span>
        </div>

        <div class="item-detail flex items-center my-1">
          <mat-icon class="text-red-700">sim_card</mat-icon>
          <label class="item-label font-bold mx-1">{{ 'ESIM.TYPE' | translate }}: </label>
          <span class="item-value">
            {{ "ESIM."+package.operador[0].plan_type | uppercase | translate }}. {{ package.operador[0].esimtype }}
          </span>
        </div>
        <div class="item-detail flex items-center mt-1">
          <mat-icon class="text-red-700">date_range</mat-icon>
          <label class="item-label font-bold mx-1">{{ 'ESIM.VALIDITY' | translate }}: </label>
          <span class="item-value">
            {{ package.day }} {{ package.day > 1 ? ('ESIM.DAYS' | translate) : ('ESIM.DAY' | translate) }}
          </span>
        </div>

      </mat-card-content>

      <mat-card-actions>
        <div class="buybutton mt-4">
          <!--button mat-button (click)="openBuyNowDialog(package)">{{ 'ESIM.BUY_NOW' | translate }}</button -->
          <button mat-button class="view-btn" (click)="openEsimDetailsDialog(package)">{{ 'ESIM.BUY_NOW' | translate }}</button>
        </div>
      </mat-card-actions>
    </mat-card>
  </div>


  <!-- Lista de eSIMs del usuario -->
  <div *ngIf="showEsims" class="grid my-6 !gap-2">
    <h2 class="text-xl font-bold text-center">
      <mat-icon (click)="toogleEsimSearcher()" class="float-left ms-4 cursor-pointer">arrow_back_ios</mat-icon>{{ 'ESIM.MY_ESIM' | translate }}
    </h2>
  </div>

  <div *ngIf="showEsims" class="grid md:grid-cols-2 gap-4 mt-2">
    <div *ngIf="!IsOnline; else esimsList">
      <div class="flex flex-col items-center p-4">
        <h2>{{ 'ONBOARDING.LOGIN.TITLE' | translate }}</h2>
      </div>
      <form class="md:max-w-xl md:m-auto" [formGroup]="loginForm">
        <div class="flex flex-col items-center p-4">
          <mat-form-field class="calls-full-width">
            <mat-label>{{ 'ONBOARDING.LOGIN.USER' | translate }}</mat-label>
            <input type="text" matInput formControlName="username" />
          </mat-form-field>
          <mat-form-field class="calls-full-width">
            <mat-label>{{ 'ONBOARDING.LOGIN.PASS' | translate }}</mat-label>
            <input type="password" matInput formControlName="password" />
          </mat-form-field>
          <app-button (click)="login()">
            {{ 'ONBOARDING.LOGIN.BUTTON' | translate }}
          </app-button>
        </div>
        <div class="flex flex-col items-center p-4">
          <a class="font-medium" routerLink="/onboarding/forgot" [replaceUrl]="true">
            {{ 'ONBOARDING.LOGIN.FORGOT' | translate }}
          </a>
        </div>
      </form>
    </div>

    <!-- Mostrar lista de eSIMs si el usuario está logueado -->
    <ng-template #esimsList>
      <!-- Lista de eSIMs del usuario -->
      <div *ngIf="showEsims" class="grid grid-cols-1 md:grid-cols-2 gap-4 mt-2">
        <div *ngIf="!IsOnline; else esimsList">
          <h2>{{ 'ONBOARDING.LOGIN.TITLE' | translate }}</h2>
          <form class="md:max-w-xl md:m-auto" [formGroup]="loginForm">
            <div class="flex flex-col items-center p-4">
              <mat-form-field class="calls-full-width">
                <mat-label>{{ 'ONBOARDING.LOGIN.USER' | translate }}</mat-label>
                <input type="text" matInput formControlName="username" />
              </mat-form-field>
              <mat-form-field class="calls-full-width">
                <mat-label>{{ 'ONBOARDING.LOGIN.PASS' | translate }}</mat-label>
                <input type="password" matInput formControlName="password" />
              </mat-form-field>
              <app-button (click)="login()">
                {{ 'ONBOARDING.LOGIN.BUTTON' | translate }}
              </app-button>
            </div>
            <div class="flex flex-col items-center p-4">
              <a class="font-medium" routerLink="/onboarding/forgot" [replaceUrl]="true">
                {{ 'ONBOARDING.LOGIN.FORGOT' | translate }}
              </a>
            </div>
          </form>
        </div>

        <!-- Mostrar lista de eSIMs si el usuario está logueado -->
        <ng-template #esimsList>
          <div *ngIf="userEsims.length > 0; else noEsims">
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
              <mat-card *ngFor="let esim of userEsims" class="esim-card" appearance="outlined">

                <mat-card-header class="mb-2 flex justify-center items-top">
                  <div class="absolute left-0 ml-4 p-2 flex" style="height:fit-content;">
                    <div *ngFor="let item of esim.pkg_details.operator_info.plan_type.split('-')">
                      <img width="16" [src]="'./assets/icons/' + item + '_ico.svg'" title="{{ item }}" class="mx-1" />
                    </div>
                  </div>
                  <div class="rounded-full bg-white absolute right-0 mr-4 p-2" style="height:fit-content;">
                    <span class=" flag large block content-center"><img src="{{ esim.pkg_details.country_url }}" /></span>
                  </div>

                  <div class="esim-my-card-image-container relative ml-4">
                    <img src="https://cdn-dev.zendy.tel/images/Esim/EsimCard@2x.png" alt="SIM Card" class="esim-my-card-image" />
                    <span class="esim-my-country-label">{{ esim.country }}</span> <!-- Nombre del país en la imagen -->
                  </div>
                </mat-card-header>

                <mat-card-content>
                  <mat-card-subtitle class="font-bold text-center">{{ esim.details.iccid }}</mat-card-subtitle>
                  <mat-card-title class="font-bold text-center">{{ esim.country | titlecase }}</mat-card-title>
                  <mat-card-subtitle class="text-center mb-3"><span class="font-bold">{{ "ESIM.PURCHASE_DETAILS.VALIDITY" | translate }}:</span> {{ esim.validity }}</mat-card-subtitle>

                  <!-- Datos -->
                  <div class="progress-section" *ngIf="(esim.usage_data?.total != 0)">
                    <div class="label-container">
                      <strong>{{ 'ESIM.DATA' | translate }}:</strong>
                      <p class="item-value right-align">
                        {{ 'ESIM.USED' | translate }}: {{ esim.usage_data?.total ? esim.usage_data.total - esim.usage_data.remaining : 0 }}MB
                        {{ 'ESIM.OF' | translate }} {{ esim.usage_data?.total ? esim.usage_data.total : 0 }}MB
                      </p>
                    </div>
                    <mat-progress-bar mode="determinate"
                                      [value]="calculatePercentage(esim.usage_data?.total, esim.usage_data?.remaining)"
                                      class="progress-bar"></mat-progress-bar>
                  </div>

                  <!-- Voz -->
                  <div class="progress-section" *ngIf="(esim.usage_data?.total_voice != 0)">
                    <div class="label-container">
                      <strong>{{ 'ESIM.VOICE' | translate }}:</strong>
                      <p class="item-value right-align">
                        {{ 'ESIM.USED' | translate }}: {{ esim.usage_data?.total_voice ? esim.usage_data.total_voice - esim.usage_data.remaining_voice : 0 }}
                        min {{ 'ESIM.OF' | translate }} {{ esim.usage_data?.total_voice ? esim.usage_data.total_voice : 0 }} min
                      </p>
                    </div>
                    <mat-progress-bar mode="determinate"
                                      [value]="calculatePercentage(esim.usage_data?.total_voice, esim.usage_data?.remaining_voice)"
                                      class="progress-bar"></mat-progress-bar>
                  </div>

                  <!-- SMS -->
                  <div class="progress-section" *ngIf="(esim.usage_data?.total_text != 0)">
                    <div class="label-container">
                      <strong>SMS:</strong>
                      <p class="item-value right-align">
                        {{ 'ESIM.USED' | translate }}: {{ esim.usage_data?.total_text ? esim.usage_data.total_text - esim.usage_data.remaining_text : 0 }}
                        {{ 'ESIM.OF' | translate }} {{ esim.usage_data?.total_text ? esim.usage_data.total_text : 0 }}
                      </p>
                    </div>
                    <mat-progress-bar mode="determinate"
                                      [value]="calculatePercentage(esim.usage_data?.total_text, esim.usage_data?.remaining_text)"
                                      class="progress-bar"></mat-progress-bar>
                  </div>
                </mat-card-content>

                <mat-card-actions class="buybutton mt-auto bottom-0 left-0 ">
                  <div class="mr-4">
                    <button mat-button class="secondary-btn" (click)="openTopupsDialog(esim.details.iccid)">{{ 'ESIM.TOPUP' | translate }}</button>
                  </div>
                  <div>
                    <button mat-button class="view-btn shadow-xl" (click)="openEsimDetailsDialog(esim)">{{ 'ESIM.BTN_DETAILS' | translate }}</button>
                  </div>
                </mat-card-actions>
              </mat-card>
            </div>
          </div>
        </ng-template>
      </div>

      <ng-template #noEsims>
        <div class="no-esims-container text-center">
          <div class="icon-container mb-4">
            <img src="./assets/icons/no-esims-icon.png" alt="No eSIMs" class="no-esims-icon" />
          </div>
          <h2 class="no-esims-title">{{ 'ESIM.NO_SIMS_REGISTERED' | translate }}</h2>
          <p class="no-esims-subtitle">
            {{ 'ESIM.NO_SIMS_DESCRIPTION' | translate }}
          </p>
        </div>
      </ng-template>
    </ng-template>
  </div>
