import { Component, OnInit, inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { OnboardingService } from '../onboarding.service';
import { AWSLambda } from 'src/app/AWSLambda.service';

@Component({
  selector: 'app-email-code-validation',
  templateUrl: './email-code-validation.component.html',
})

export class EmailCodeValidationComponent implements OnInit {
  router = inject(Router);
  onboardingService = inject(OnboardingService);
  authService = inject(AuthService);
  _snackBar = inject(MatSnackBar);
  validationCode!: string;
  errorCode = false;

  constructor(

    private lambda: AWSLambda
  ) { }

  ngOnInit(): void {
    if (!this.onboardingService.username) {
      this.router.navigate(['/onboarding/login'], { replaceUrl: true });
    }
  }

  async validate() {
    if (this.validationCode) {
      this.errorCode = false;
      try {
        await this.authService.confirmSignUp(
          this.onboardingService.username,
          this.validationCode
        );
        //await this.lambda.getLambdaFunction("lambda", "/crm/contact/update")
    
        this.router.navigate(['/onboarding/login'], { replaceUrl: true });
      } catch (e: any) {
        if (e.code === 'CodeMismatchException') {
          this.errorCode = true;
        }
      }
    }
  }

  async resendCode() {
    await this.authService.resendSignUp(this.onboardingService.username);
    this._snackBar.open('The code was sended', 'Close', {
      duration: 5000,
    });

    // await this.authService.confimacion(this.onboardingService.username);
    // console.log(this.onboardingService.username)
    // this._snackBar.open('The code was sended to your phone', 'Close', {
    //   duration: 5000,
    // });
  }
}
