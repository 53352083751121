import { Component, OnInit, inject ,Inject} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AmplifyUser } from '@aws-amplify/ui';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { ConfigService } from 'src/app/config.service';
import { AuthService } from 'src/app/services/auth.service';
import { TUI_IS_MOBILE } from "@taiga-ui/cdk";
import { Auth } from 'aws-amplify';
import { BalanceService } from 'src/app/services/balance.service';
import { DeleteAccountComponent } from './delete-account/delete-account.component';
import { VerifyNumberComponent } from './verify-number/verify-number.component';
import { AWSLambda } from 'src/app/AWSLambda.service';
import { VerificationNumberAttempt } from 'src/models/extra';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
})
export class MyProfileComponent implements OnInit {
  
  
  user!: AmplifyUser;
  //authenticatorSvc = inject(AuthenticatorService);
  //auth = inject(AuthService);
  lambda = inject(AWSLambda);
  dialog = inject(MatDialog);
  transfersUrl = `${inject(ConfigService).config.transfersUrl}?tracker=${inject(AuthService).getUser().id}`;
  private balanceSvc = inject(BalanceService);
  name: string | undefined;
  phone_number: string | undefined;
  email: string | undefined;

  middle_name: string | undefined;
  address: string | undefined;
  country: string | undefined;

  ciudad: string | undefined;
  postcode: string | undefined;
  passport: string | undefined;

  balance!: number;
  currency: string = `EUR`;
  signalwire_verified_phone_number: boolean | undefined;
  phone_number_verified= false ;
  isVerificationInProgress = false;

  constructor(
    @Inject(TUI_IS_MOBILE) public mobile: boolean,
    private authenticatorSvc: AuthenticatorService,
    private auth: AuthService
      ) {
      
      }

  async ngOnInit() {
    await this.auth.refresh()
    this.user = await Auth.currentAuthenticatedUser();
    this.balance = await this.balanceSvc.calculateBalance();
    this.lambda.getLambdaFunction("lambda", "/get/userDetails").then((data) => {
      this.ciudad = data['custom:ciudad'];
      this.postcode = data['custom:postcode'];
      this.passport = data['custom:passport'];
  })

    if (this.user.attributes?.['name'] == undefined || this.user.attributes?.['name'] == ' ') this.name = ''; else this.name = this.user.attributes?.['name'] + ' ' + this.user.attributes?.['middle_name'];
    if (this.user.attributes?.['phone_number'] != undefined) this.phone_number = this.user.attributes?.['phone_number'];
    if (this.user.attributes?.['email'] != undefined) this.email = this.user.attributes?.['email'];

    if (this.user.attributes?.['middle_name'] != undefined) this.middle_name = this.user.attributes?.['middle_name'];
    if (this.user.attributes?.['custom:address'] != undefined) this.address = this.user.attributes?.['custom:address'];
    if (this.user.attributes?.['custom:country'] != undefined) this.country = this.user.attributes?.['custom:country'];

    // if (this.user.attributes?.['custom:ciudad'] != undefined) this.ciudad = this.user.attributes?.['custom:ciudad'];
    // if (this.user.attributes?.['custom:postcode'] != undefined) this.postcode = this.user.attributes?.['custom:postcode'];
    // if (this.user.attributes?.['custom:passport'] != undefined) this.passport = this.user.attributes?.['custom:passport'];
    //console.log(this.user.attributes?.['phone_number_verified'] === 'true' )
    this.phone_number_verified = this.user.attributes?.['phone_number_verified'] === 'true';
    const attributesJson = JSON.stringify(this.user.attributes);
    const objJson = JSON.parse(attributesJson);



    //console.log(this.user.attributes)
    this.signalwire_verified_phone_number = objJson['phone_number_verified']
  }

  openModal() {
    this.dialog
      .open(DeleteAccountComponent)
      .afterClosed()
      .subscribe((confirm: Boolean) => {
        if (confirm) {
          this.auth.deleteAccount();
        }
      });
  }

  openVerification() {
    // Send an code to Signalwire
    console.log(this.user.attributes)
    this.isVerificationInProgress = true;
    this.lambda.getLambdaFunction("lambda", "/aws/verification/send", {
      queryStringParameters: { phone_number: this.user.attributes?.phone_number, lang: this.user.attributes?.['locale'] ? this.user.attributes?.['locale'] : 'es' },
    }).then( (data) => {
        this.dialog
          .open(VerifyNumberComponent, {
            data: Object.assign({}, data, { phone_number: this.user.attributes?.phone_number })
          })
          .afterClosed()
          .subscribe(() => {
            // Recargar la página después de que el diálogo se haya cerrado
            window.location.reload();
          });
    }).catch( () => {
      // Error
    }).finally(() => {
      // Habilitar el botón de nuevo después de que se complete la operación
      this.isVerificationInProgress = false;
    });
  } 
  
}
