import { inject, Injectable } from '@angular/core';
import { AuthService } from '../../../app/services/auth.service';
import { AWSLambda } from '../../AWSLambda.service';
import { MatDialog } from '@angular/material/dialog';
import { TPVService } from '../../services/tpv.service';
import { EsimLoginComponent } from '../esim-login/esim-login.component';

@Injectable({
    providedIn: 'root'
})

export class EsimService {
    lambda = inject(AWSLambda);
    authService = inject(AuthService);
    tpvService = inject(TPVService);
    dialog = inject(MatDialog);

    async init() {
      await this.authService.refresh();
    }

    // Verifica si el usuario está logueado
    async checkUserLoggedIn(): Promise<boolean> {
        const user = this.authService.getUser();
        return !!user;  // Devuelve true si el usuario está logueado, false si no
    }

    async getusuario() {
        try {

            return this.authService.getUser().username ?? '';
        } catch {
            console.log('error no logueado')
            return ''
        }
    }

    // Abre el modal de login
    openLoginModal(): void {
        this.dialog.open(EsimLoginComponent, {
            width: '400px',
        });
    }

    // Envia la orden de compra de un un esim
    async submitOrder(orderData: { packageId: string, country: string, operatorTitle: string }, tpvType: string, amount: string) {
        const accId = `${orderData.country},${orderData.operatorTitle}`;
        //console.log(orderData.operatorTitle);
        await this.tpvService.createFormAndSendForm(amount, tpvType, orderData.packageId, accId);
    }

    async submitTopUpOrder(amount: string, tvpType: string, package_id: string, iccid: string) {
        await this.tpvService.createFormAndSendForm(amount, tvpType, package_id, iccid);
    }

    async getEsimDetails(iccid: string) {
        const details = await this.lambda.getLambdaFunction(
            'lambda',
            '/esim/getDetails',
            {
                queryStringParameters: { iccid: iccid }
            }
        );
        return details;
    }


    async getEsimList() {
        const list = await this.lambda.getLambdaFunction(
            'lambda',
            '/esim/getUserEsimList',
            { queryStringParameters: { owner: this.authService.getUser().username } }
        );
        return list;
    }

    async activateEsim(esimId: string, activate: boolean) {
        const resgistered = await this.lambda.getLambdaFunction(
            'lambda',
            '/eSims/registerEsim',
            {
                queryStringParameters: { esimId: esimId, activate: activate }
            }
        );
        return resgistered;
    }

    async topupList(iccid: string) {
        const packages = await this.lambda.getLambdaFunction(
            'lambda',
            '/esim/topUpList',
            {
                queryStringParameters: { iccid: iccid }
            }
        );
        return packages;
    }

    async getPackages(): Promise<string> {
        const packages = await this.lambda.getLambdaFunction(
            'lambda',
            '/esim/getPackages'
        );
        return packages;
    }

    //filtra los paquetes mediante la tipo --> Local ,Regional o Global
    async getfilteredPackages(tipo: string): Promise<string> {
      const packages = await this.lambda.getLambdaFunction(
        'lambda',
        '/esim/get/filterpackages',
        {
          queryStringParameters: { type: tipo }
        }
      );
      return packages;
    }

    //filtra los paquetes mediante el ID
    getfilteredPackagesbyID(id: string): Promise<string> {
      const packages = this.lambda.getLambdaFunction(
        'lambda',
        '/esim/get/filterpackages',
        {
          queryStringParameters: { id: id }
        }
      );
      return packages;
    }

    async getInstallationGuide(iccid: string) {
        const packages = await this.lambda.getLambdaFunction(
            'lambda',
            '/esim/getInstallationGuide',
            {
                queryStringParameters: { iccid: iccid }
            }
        );
        return packages;
    }
}
