import { Component, inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ButtonModule } from '../button/button.module';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-complete-profile-modal',
  templateUrl: './complete-profile-modal.component.html',
  standalone: true,
  imports: [
    TranslateModule,
    ButtonModule
  ]
})
export class CompleteProfileModalComponent {
  router = inject(Router);
  constructor(public dialog: MatDialogRef<CompleteProfileModalComponent>) {}

  close(): void {
    this.dialog.close(false);
  }
  confirm(): void {
    this.dialog.close(true);
    this.router.navigate(['/profile/edit'], { replaceUrl: true });
  }
}
