<mat-dialog-content>
  <div class="flex flex-col justify-center items-center">
    <div class="flex justify-center">
      <img src="./assets/icons/my-profile-change-password.svg" />
    </div>
    <h2 class="text-center mt-6">
      {{ 'COMPONENTS.PROFILE.MY_PROFILE.VERIFY_MY_NUMBER.TITLE' | translate }}
    </h2>
    <p class="mt-4 text-base text-center">AAA
      {{ 'COMPONENTS.PROFILE.MY_PROFILE.VERIFY_MY_NUMBER.TEXT' | translate }}
    </p>
    <br/>
    <form [formGroup]="createForm">
      <mat-form-field class="calls-full-width">
        <input
          formControlName="code"
          autofocus
          type="text"
          matInput
          placeholder="______"
        />
          <mat-error *ngIf="hasError">{{ 'COMPONENTS.PROFILE.MY_PROFILE.VERIFY_MY_NUMBER.ERROR' | translate }}</mat-error>
      </mat-form-field>
    </form>
    <div class="flex justify-between gap-4 mt-6">
      <app-button expand="full" color="secondary" (click)="goBack()"
        >{{'COMMON.CANCEL' | translate}}</app-button
      >
      <app-button expand="full" (click)="onVerify()" [disabled]="createForm.invalid">{{'COMMON.VERIFY' | translate}}</app-button>
    </div>
  </div>
</mat-dialog-content>
