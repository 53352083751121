import { inject, Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { AWSLambda } from '../AWSLambda.service';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { Auth } from 'aws-amplify';

@Injectable({
  providedIn: 'root'
})
export class BalanceService {

  lambda = inject(AWSLambda)
  authService = inject(AuthService)

  private user = inject(AuthenticatorService).user;
  auth = inject(AuthService);

  async calculateBalance(username?: string) {
    await this.auth.refresh();
    this.user = await Auth.currentAuthenticatedUser();

    const balance = await this.lambda.getLambdaFunction(
      'lambda',
      '/transactions/balance',
      //{ queryStringParameters: { owner: username ? username : this.authService.getUser().username } }
      { queryStringParameters: { owner: username ? username : this.user.username } }
    );
    return parseFloat(parseFloat(balance.amount).toFixed(2));
  }
}
