<div class="p-4 bg-gray-opacity2 rounded-2xl mt-8 flex items-center justify-between flex-col">
  <img width="64px" class="rounded-full" src="./assets/icons/profile-icon.svg" />
  <div class="w-full flex flex-col">
    <div class="flex items-center flex-col gap-2 mt-8 mb-8">
      <h2 class="m-0 tracking-tight text-gray font-semibold">
        {{ !name ? phone_number : name }}
      </h2>
      <div class="text-gray text-base">
        {{ !name ? '' : phone_number }}
        <ng-container *ngIf="signalwire_verified_phone_number; then verified else notVerified"></ng-container>
        <ng-template #verified>
          <mat-chip color="secondary">{{ 'COMPONENTS.PROFILE.MY_PROFILE.VERIFIED' | translate }}</mat-chip>
        </ng-template>
        <ng-template #notVerified>
          <mat-chip>{{ 'COMPONENTS.PROFILE.MY_PROFILE.NOT_VERIFIED' | translate }}</mat-chip>
        </ng-template>
      </div>
      <div class="text-gray text-base">
        {{ email }}
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="flex flex-row mt-8 mb-8 w-full justify-evenly md:justify-center md:gap-16">
      <div class="flex flex-col items-center gap-2">
        <button 
          routerLink="edit" 
          class="text-black font-bold py-2 px-4 rounded hover:bg-gray-200 transition flex items-center justify-center">
          <img src="./assets/icons/edit-profile.svg" alt="Edit Profile" />
        </button>
        <div class="text-gray text-base text-center">
          {{ 'COMPONENTS.PROFILE.MY_PROFILE.EDIT' | translate }}
        </div>
      </div>
      <div *ngIf="!signalwire_verified_phone_number && phone_number" class="flex flex-col items-center gap-2">
        <button 
          (click)="openVerification()" 
          [disabled]="isVerificationInProgress"
          class="text-black font-bold py-2 px-4 rounded disabled:bg-gray-400 hover:bg-gray-200 transition">
          <img src="./assets/icons/my-profile-change-password.svg" alt="Verify" />
        </button>
        <div class="text-gray text-base text-center">
          {{ 'COMPONENTS.PROFILE.MY_PROFILE.VERIFY_MY_NUMBER.TITLE' | translate }}
        </div>
      </div>
      <div class="flex flex-col items-center gap-2">
        <button 
          (click)="openModal()" 
          [disabled]="isVerificationInProgress"
          class="text-black font-bold py-2 px-4 rounded disabled:bg-gray-400 hover:bg-gray-200 transition">
          <img src="./assets/icons/my-profile-trash.svg" />
        </button>
        <div class="text-gray text-base text-center">
          {{ 'COMPONENTS.PROFILE.MY_PROFILE.DELETE' | translate }}
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="my-8">
      <div class="flex flex-row justify-between w-full">
        <img class="relative rotate-minus12" width="147px" src="./assets/icons/card.svg" alt="card" />
        <p class="flex flex-col mb-2 mt-4 mr-2 tracking-tight text-gray items-end">
          {{ 'COMPONENTS.HEADER.BALANCE' | translate }}
          <span class="text-light-red text-2xl items-end text-right">
            {{ balance | currency : currency : 'symbol' }}
          </span>
          <a class="text-sm text-light-red" routerLink="/recharges">{{
            'COMPONENTS.HEADER.ADD' | translate
            }}</a>
        </p>
      </div>
    </div>
  </div>
</div>
