<mat-list class="!mt-16 !gap-y-8">
    <!-- a mat-list-item class="!cursor-pointer" routerLink="/profile/edit" >
        <img
          matListItemIcon
          src="./assets/icons/VerifyNumber.svg"
          alt="help"
          class="!m-0"
        />
        <h3 class="text-white ml-2">
          {{ 'COMPONENTS.MENU.VERIFICANUMERO' | translate }}
        </h3>
      </a-->
      <a mat-list-item class="!cursor-pointer" routerLink="/recharges">
        <img
          matListItemIcon
          src="./assets/icons/recarcargas.svg"
          alt="help"
          class="!m-0"
        />
        <h3 class="text-white ml-2">
          {{ 'COMPONENTS.MENU.RECARGA' | translate }}
        </h3>
      </a>
      <a mat-list-item class="!cursor-pointer" routerLink="/recharges">
        <img
          matListItemIcon
          src="./assets/icons/Rates.svg"
          alt="help"
          class="!m-0"
        />
        <h3 class="text-white ml-2">
          {{ 'COMPONENTS.MENU.TARIFA' | translate }}
        </h3>
      </a>
      <a mat-list-item class="!cursor-pointer" routerLink="/home">
        <img
          matListItemIcon
          src="./assets/icons/Promotions.svg"
          alt="help"
          class="!m-0"
        />
        <h3 class="text-white ml-2">
          {{ 'COMPONENTS.MENU.PROMOCION' | translate }}
        </h3>
      </a>






    <a mat-list-item (click)="window.open('https://zendy.tel/terms', '_blank')" class="!cursor-pointer">
      <img
        matListItemIcon
        src="./assets/icons/conditions.svg"
        alt="conditions"
        class="!m-0"
      />
      <h3 class="text-white ml-2">
        {{ 'COMPONENTS.MENU.CONDITIONS' | translate }}
      </h3>
    </a>
    <a mat-list-item class="!cursor-pointer" (click)="window.open('https://zendy.tel/faq', '_blank')">
      <img
        matListItemIcon
        src="./assets/icons/help.svg"
        alt="help"
        class="!m-0"
      />
      <h3 class="text-white ml-2">
        {{ 'COMPONENTS.MENU.HELP' | translate }}
      </h3>
    </a>
    <a mat-list-item class="!cursor-pointer" (click)="signOut()">
      <img matListItemIcon src="./assets/icons/logout.svg" class="!m-0" />
      <h3 class="text-white ml-2">
        {{ 'COMPONENTS.MENU.LOG_OUT' | translate }}
      </h3>
    </a>
    <ng-content></ng-content>
  </mat-list>
