<app-onboarding-header></app-onboarding-header>
<form class="md:max-w-xl md:m-auto" [formGroup]="loginForm">
  <div class="flex flex-col items-center p-4">
    <h2>{{ 'ONBOARDING.LOGIN.TITLE' | translate }}</h2>
    <p class="text-base mb-8">{{ 'ONBOARDING.LOGIN.DESC' | translate }}</p>

    <zendy-intl-phone class="w-full rounded-none mb-4"
                      formControlName="username"
                      [countries]="(countries | tuiSortCountries | async) || []"
                      [(countryIsoCode)]="countryIsoCode">
    </zendy-intl-phone>

    <!--
    <mat-form-field class="calls-full-width">
      <mat-label>{{ 'ONBOARDING.LOGIN.USER' | translate }}</mat-label>
      <input type="text" matInput formControlName="username" />
    </mat-form-field>
    -->

    <mat-form-field class="calls-full-width"> 
      <mat-label>{{ 'ONBOARDING.LOGIN.PASS' | translate }}</mat-label>
      <input type="password" matInput formControlName="password" />
    </mat-form-field>
    <app-button (click)="login()">
      {{ 'ONBOARDING.LOGIN.BUTTON' | translate }}
    </app-button>
  </div>
  <div class="flex flex-col items-center p-4">
    <a class="font-medium" routerLink="/onboarding/forgot" [replaceUrl]="true">
      {{ 'ONBOARDING.LOGIN.FORGOT' | translate }}
    </a>
  </div>
  <div class="p-4">
    <hr />
  </div>
  <div class="flex flex-col items-center p-4">
    <p class="text-sm mb-8 font-medium">
      {{ 'ONBOARDING.LOGIN.QUESTION' | translate }}
    </p>
    <app-button color="secondary"
                routerLink="/onboarding/register"
                [replaceUrl]="true">
      {{ 'ONBOARDING.LOGIN.CREATE' | translate }}
    </app-button>
  </div>
</form>
<div class="loading-mask" *ngIf="showSpinner">
  <mat-spinner diameter="50" strokeWidth="3"></mat-spinner>
</div>
