import { Component, AfterViewInit } from '@angular/core';
import { SpinnerService } from './spinner.service';

@Component({
  selector: 'app-spinner',
  template: '<div *ngIf="spinnerVisible" class="loading-mask spinner-container justify-items-center"><mat-spinner diameter="50" strokeWidth="3"></mat-spinner></div>'
})
export class SpinnerComponent implements AfterViewInit {
  spinnerVisible = false;

  constructor(private spinnerService: SpinnerService) { }

  ngAfterViewInit(): void {
    this.spinnerService.getSpinnerState().subscribe((visible) => {
      this.spinnerVisible = visible;
    });
  }
}
