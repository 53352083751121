import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TuiFlagPipeModule, TuiPrimitiveTextfieldModule, TuiRootModule, TuiTextfieldControllerModule } from '@taiga-ui/core';
import { TuiSortCountriesPipeModule } from '@taiga-ui/kit';
import { NgxPayPalModule } from 'ngx-paypal';
import { AWSLambda } from './AWSLambda.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ButtonModule } from './components/button/button.module';
import { LanguageComponent } from './components/language/language.component';
import { MenuComponent } from './components/menu/menu.component';
import { ProfileComponent } from './components/menu/profile/profile.component';
import { TabsModule } from './components/tabs/tabs.module';
import { ZendyHeaderDesktopComponent } from './components/zendy-header/zendy-header-desktop.component';
import { ZendyHeaderComponent } from './components/zendy-header/zendy-header.component';
import { FilterCountriesPipe } from './components/zendy-intl-phone/filter-countries.pipe';
import { ConfigService } from './config.service';
import { ConditionsComponent } from './features/about/conditions/conditions.component';
import { HelpComponent } from './features/about/help/help.component';
import { BalanceModule } from './features/balances/balance.module';
import { CheckoutNotificationComponent } from './features/checkout-notification/checkout-notification.component';
import { ContactDetailsComponent } from './features/contacts/contact-details/contact-details.component';
import { ContactsListComponent } from './features/contacts/contacts-list.component';
import { ContactsComponent } from './features/contacts/contacts.component';
import { NewContactComponent } from './features/contacts/new-contact/new-contact.component';
import { HeaderComponent } from './features/header/header.component';
import { HistoryListComponent } from './features/history/history-list.component';
import { HistoryComponent } from './features/history/history.component';
import { HomeComponent } from './features/home/home.component';
import { DeleteAccountComponent } from './features/my-profile/delete-account/delete-account.component';
import { EditMyProfileComponent } from './features/my-profile/edit-profile/edit-profile.component';
import { VerifyNumberComponent } from './features/my-profile/verify-number/verify-number.component';
import { MyProfileComponent } from './features/my-profile/my-profile.component';
import { OnBoardingModule } from './features/onboarding/onboarding.module';
import { CountriesModalComponent } from './features/rates/countries-modal.component';
import { RatesModalComponent } from './features/rates/rates-modal.component';
import { RechargesComponent } from './features/recharges/recharges.component';
import { SendMoneyComponent } from './features/send-money/send-money.component';
import { TransfersComponent } from './features/transfers/transfers.component';
import { UtilsModule } from './utils/utils.module';
import { TransfersListComponent } from './features/transfers/transfers-list.component';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import localeEn from '@angular/common/locales/en';
import { TuiAccordionModule, TuiActionModule } from '@taiga-ui/kit';
import { ItemDetailDialogComponent } from './components/item-detail-dialog/item-detail-dialog.component';
import { EsimComponent } from './features/esim/esim.component';
import { BuyNowModalComponent } from './features/esim/buy-now-modal/buy-now-modal.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatTabsModule } from '@angular/material/tabs';
import { TopupsModalComponent } from './features/esim/topups-modal/topups-modal.component';
import { PurchaseDetailsModalComponent } from './features/esim/purchase-details-modal/purchase-details-modal.component';
import { EsimLoginComponent } from './features/esim-login/esim-login.component';
import { EsimDetailsModalComponent } from './features/esim/esim-details-modal/esim-details-modal.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TopupComponent } from './features/esim/topup/topup.component';
import { DetailsComponent } from './features/esim/details/details.component';
import { ZendyIntlPhoneComponent } from "src/app/components/zendy-intl-phone/zendy-intl-phone.component";
import { SpinnerComponent } from './components/spinner/spinner.component';
import { SpinnerModule } from './components/spinner/spinner.module';


registerLocaleData(localeEs, 'es');
registerLocaleData(localeEn, 'en');

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    ContactsComponent,
    ContactsListComponent,
    HeaderComponent,
    TransfersComponent,
    RechargesComponent,
    HistoryComponent,
    HistoryListComponent,
    HomeComponent,
    SendMoneyComponent,
    CheckoutNotificationComponent,
    ZendyHeaderComponent,
    ZendyHeaderDesktopComponent,
    NewContactComponent,
    MyProfileComponent,
    EditMyProfileComponent,
    DeleteAccountComponent,
    VerifyNumberComponent,
    ContactDetailsComponent,
    CountriesModalComponent,
    RatesModalComponent,
    ConditionsComponent,
    HelpComponent,
    TransfersListComponent,
    ItemDetailDialogComponent,
    EsimComponent,
    BuyNowModalComponent,
    TopupsModalComponent,
    PurchaseDetailsModalComponent,
    EsimLoginComponent,
    EsimDetailsModalComponent,
    TopupComponent,
    DetailsComponent,
    SpinnerComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AmplifyAuthenticatorModule,
    UtilsModule,
    NgxPayPalModule,
    MatTableModule,
    MatButtonModule,
    MatInputModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatToolbarModule,
    MatIconModule,
    MatListModule,
    MatChipsModule,
    MatGridListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatFormFieldModule,
    TabsModule,
    ButtonModule,
    SpinnerModule,
    MatSidenavModule,
    BalanceModule,
    OnBoardingModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    MatCardModule,
    TuiRootModule,
    ProfileComponent,
    MenuComponent,
    MatDialogModule,
    MatProgressSpinnerModule,
    TuiFlagPipeModule,
    MatSelectModule,
    LanguageComponent,
    TuiPrimitiveTextfieldModule,
    TuiTextfieldControllerModule,
    FilterCountriesPipe,
    TuiSortCountriesPipeModule,
    TuiAccordionModule,
    TuiActionModule,
    NgSelectModule,
    MatTabsModule,
    MatExpansionModule,
    MatProgressBarModule,
    ZendyIntlPhoneComponent,
  ],
  providers: [ConfigService, AWSLambda],
  bootstrap: [AppComponent],
})
export class AppModule { }

