import { Component, ViewChild, ElementRef, Inject, inject } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
  ValidationErrors, 
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Auth } from 'aws-amplify';
import { OnboardingService } from '../onboarding.service';
import { AuthService } from 'src/app/services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { ConditionsComponent } from '../../about/conditions/conditions.component';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { TuiCountryIsoCode } from '@taiga-ui/i18n';
import { CHAR_PLUS, TUI_IS_MOBILE } from '@taiga-ui/cdk';
import { TUI_COUNTRIES_MASKS } from '@taiga-ui/kit/tokens';

import { tap } from 'rxjs/operators';
import { AWSLambda } from 'src/app/AWSLambda.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { firstValueFrom, of } from 'rxjs';



@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styles: [
    `
      .t-textfield, .t-country-select{
        border-radius: 0!important;
      }
      .loading-mask {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(
          0,
          0,
          0,
          0.5
        ); /* semi-transparent black background */
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999; /* Ensure it's on top of other content */
      }
    `
  ]
})



export class RegisterComponent {
  showSpinner = false;
  tokenRecaptcha: string | undefined;
  router = inject(Router);
  _snackBar = inject(MatSnackBar);
  authService = inject(AuthService);
  onboardingService = inject(OnboardingService);
  dialog = inject(MatDialog);

  readonly countries = Object.values(TuiCountryIsoCode);
  countryIsoCode = TuiCountryIsoCode.ES;

  private recaptchaResponse: any;
  public isLoading: boolean = false;
  public acceptTermsControl : boolean = false;

  minScoreRecap: number = 0.7;
  recaptchaResult: { verified: boolean; score: number } | null = null;

  public registerForm = new FormGroup(
    {
      username: new FormControl('', [Validators.required, Validators.pattern(/^\+\d{7,15}$/),]),
      // email: new FormControl('', [Validators.required, Validators.pattern(/^\S*$/),]),
      password: new FormControl('', [Validators.required, Validators.pattern(/^\S*$/),]),
      password2: new FormControl('', [Validators.required, Validators.pattern(/^\S*$/),]),
    },
    MustMatch('password', 'password2')
  );

  constructor(
    @Inject(TUI_IS_MOBILE)
    readonly isMobile: boolean,
    private lambda: AWSLambda,
    private recaptchaV3Service: ReCaptchaV3Service,
    @Inject(TUI_COUNTRIES_MASKS) private readonly countriesMasks: Record<TuiCountryIsoCode, string>,
  ) { }


  async signUp() {
    if (this.registerForm.invalid) return;
    this.showSpinner = true;

    try {

      const token = await firstValueFrom(this.recaptchaV3Service.execute('importantAction'));
      const response = await this.lambda.getLambdaFunction('lambda', '/security/recaptcha', {
        queryStringParameters: { token }
      });
      if (response.verified) {

        //ejecutar despues de la validacion del recaptcha

        const formData = this.registerForm.getRawValue();
        if (formData.username && formData.password) {
          //console.log('intentando registrar');
          this.onboardingService.setUsername(formData.username.toLowerCase());
          try {
            await this.authService.register(
              formData.username,
              formData.password
            );

            this.router.navigate(['onboarding/validation'], { replaceUrl: true });
          } catch (e: any) {
            this._snackBar.open(e.message, 'Close', { duration: 5000 });
          }
        }
       
      } else {
        this._snackBar.open('Recaptcha no verificado, intente nuevamente', 'Close', { duration: 5000 });
        
      }
      this.showSpinner = false;
      return null;
    } catch (error) {
      this._snackBar.open('Error al enviar el token al servidor', 'Close', { duration: 5000 });
      this.showSpinner = false;

      return null;
    }
  }


  openConditions() {
    this.dialog.open(ConditionsComponent, {
      width: this.isMobile ? '95%' : '60%',
      height: '90%',
      panelClass: 'zendy-modal',
    });
  }


  toggleTerms() {
    this.acceptTermsControl = !this.acceptTermsControl
    /*if(this.acceptTermsControl)
      {
        this.openConditions();
      }*/
  }
}

export function noWhitespaceValidator(control: AbstractControl): ValidationErrors | null {
  const isWhitespace = (control.value || '').trim().length === 0;
  const isValid = !isWhitespace;
  return isValid ? null : { 'whitespace': true };
}

export function MustMatch(
  controlName: string,
  matchingControlName: string
): ValidatorFn {
  return (formGroup: AbstractControl): { [key: string]: boolean } | null => {
    const control = formGroup.get(controlName);
    const matchingControl = formGroup.get(matchingControlName);

    if (control && matchingControl && control.value !== matchingControl.value) {
      return { mustMatch: true };
    }

    return null;
  };
}
function openConditions2() {
  throw new Error('Function not implemented.');
}

