<div *ngIf="isMobile"
     class="max-w-sm p-6 bg-gray-opacity rounded-2xl shadow h-40 mt-8 flex flex-col items-center">
  <img class="relative top-minus30 rotate-minus12"
       width="147px"
       src="./assets/icons/card.svg" />
  <p class="mb-2 tracking-tight text-gray flex items-center gap-2">
    {{ 'RECHARGES.BALANCE' | translate }}
    <span class="text-light-red text-2xl">{{ balance }} {{ currency }}</span>
  </p>
</div>
<div class="md:max-w-md md:m-auto">
  <div *ngIf="!isMobile" class="my-4">
    <app-balance></app-balance>
  </div>
  <div>
    <h2>{{ 'RECHARGES.ADD' | translate }}</h2>
    <app-spinner></app-spinner>
    <div class="grid grid-cols-3 gap-4">
      <button *ngFor="let item of items"
              class="bg-gradient-to-t to-light-red from-zendy-dark-red text-white rounded-lg px-4 py-2"
              (click)="sendToProfile ? openProfileModal() : topUp(item.value)">
        <div class="flex flex-col">
          <h1>{{ item.value }}</h1>
          {{ currency }}
        </div>
      </button>
    </div>
  </div>
  <p class="text-center mt-8 mb-6">
    {{ 'RECHARGES.QUESTION' | translate }}
  </p>
  <div class="flex justify-center mt-6 mb-10">
    <app-button hasIcon="hasIcon" (click)="openCountriesModal()">
      <img width="24px" height="24px" src="./assets/icons/rates-button.svg" />
      {{ 'RECHARGES.RATES_BUTTON' | translate }}
    </app-button>
  </div>
  <div class="loading-mask" *ngIf="showSpinner">
    <mat-spinner diameter="50" strokeWidth="3"></mat-spinner>
  </div>
</div>
