import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AWSLambda } from 'src/app/AWSLambda.service';
import {
  APIService,
  GetContactQuery,
  ListCallsQuery,
  ListTransactionsQuery,
  ModelCallFilterInput,
} from 'src/app/API.service';
import { PhoneNumber } from 'google-libphonenumber';

@Component({
  selector: 'app-contact-details',
  templateUrl: './contact-details.component.html',
})
export class ContactDetailsComponent implements OnInit {
  route = inject(ActivatedRoute);
  api = inject(APIService);
  contactId = this.route.snapshot.params['id'];
  contact!: GetContactQuery;
  //calls!: ListCallsQuery;
  //transfers!: ListTransactionsQuery;
  lambda = inject(AWSLambda);
  calls: any[] = [];


  async ngOnInit() {
    if (this.contactId) {
      this.contact = await this.api.GetContact(this.contactId);
      this.calls = await this.lambda.getLambdaFunction("lambda", "/call/User/History", {
        queryStringParameters: { items: 6,PhoneNumber :this.contact.telephone },
      });

      // Ordenamos las llamadas por la fecha de creación
      this.calls.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

      // Formateamos el labelTime de cada llamada
      this.calls = this.calls.map(call => ({
        ...call,
        formattedLabelTime: this.formatTime(call.labelTime),  // Convertimos el tiempo a formato hh:mm:ss.mmm
      }));
    }
  }

  // Función para convertir labelTime (en segundos) a formato hh:mm:ss.mmm
  formatTime(seconds: number): string {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);
    const milliseconds = Math.round((seconds % 1) * 1000);  // Obtener milisegundos

    // Retornar el tiempo formateado como hh:mm:ss.mmm
    return `${this.padNumber(hours)}:${this.padNumber(minutes)}:${this.padNumber(secs)}.${this.padNumber(milliseconds, 3)}`;
  }

  // Función para rellenar con ceros en caso de ser necesario
  private padNumber(num: number, size: number = 2): string {
    let str = num.toString();
    while (str.length < size) {
      str = '0' + str;
    }
    return str;
  }
}
