import { Component, OnInit, inject ,Inject} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { AuthService } from 'src/app/services/auth.service';
import { TUI_IS_MOBILE } from "@taiga-ui/cdk";
import { AWSLambda } from 'src/app/AWSLambda.service';
import { Auth } from 'aws-amplify';

@Component({
  selector: 'app-edit-my-profile',
  templateUrl: './edit-profile.component.html',
})
export class EditMyProfileComponent implements OnInit {

  user = inject(AuthenticatorService).user;
  auth = inject(AuthService);
  _snackBar = inject(MatSnackBar);
  router = inject(Router);
  name: string | undefined;

  middle_name: string | undefined;
  address: string | undefined;
  country: string | undefined;

  ciudad: string | undefined;
  postcode: string | undefined;
  passport: string | undefined;


   constructor(@Inject(TUI_IS_MOBILE) 
   public isMobile: boolean,
   private lambda: AWSLambda
  ) {

    }

  createForm: FormGroup = new FormGroup({
    name: new FormControl(''),
    middle_name: new FormControl(''),
    'custom:address': new FormControl('', Validators.required),
    'custom:country': new FormControl('', Validators.required),
    email: new FormControl('', [Validators.email, Validators.required ]),
    phone_number: new FormControl({ value: '', disabled: true }, [Validators.required,
      Validators.pattern( '^\\+(?:[0-9] ?){6,14}[0-9]$' ),
    ]),

    'custom:ciudad': new FormControl('', Validators.required),
    'custom:postcode': new FormControl('', Validators.required),
    'custom:passport': new FormControl('', Validators.required),
  });

  async ngOnInit() {
    await this.auth.refresh();
    this.user = await Auth.currentAuthenticatedUser();
    console.log(this.isMobile)
      this.lambda.getLambdaFunction("lambda", "/get/userDetails").then((data) => {
        console.log(data)
        this.ciudad = data['custom:ciudad'];
        this.postcode = data['custom:postcode'];
        this.passport = data['custom:passport'];

        this.middle_name = data['middle_name'];
        this.address = data['custom:address'];
        this.country = data['custom:country'];

        this.createForm.patchValue({
          name: data['name'],
          phone_number: this.user.attributes?.phone_number,
          email: this.user.attributes?.email,
          
          middle_name: this.middle_name,
          'custom:address': this.address,
          'custom:country': this.country,
    
          'custom:ciudad': this.ciudad,
          'custom:postcode': this.postcode,
          'custom:passport': this.passport,
    
        });
    })

    // if (this.user.attributes?.['name'] != undefined) this.name = this.user.attributes?.['name'];
    // if (this.user.attributes?.['middle_name'] != undefined) this.middle_name = this.user.attributes?.['middle_name'];
    // if (this.user.attributes?.['custom:address'] != undefined) this.address = this.user.attributes?.['custom:address'];
    // if (this.user.attributes?.['custom:country'] != undefined) this.country = this.user.attributes?.['custom:country'];
    

    // console.log(this.address)
    // this.createForm.patchValue({
    //   name: this.name,
    //   phone_number: this.user.attributes?.phone_number,
    //   email: this.user.attributes?.email,
      
    //   middle_name: this.middle_name,
    //   'custom:address': this.address,
    //   'custom:country': this.country,

    //   'custom:ciudad': this.ciudad,
    //   'custom:postcode': this.postcode,
    //   'custom:passport': this.passport,

    // });
  }

  async onUpdate() { 
    const form = this.createForm;
    const formValue = form.getRawValue();
    console.log(formValue)
    console.log(formValue['custom:address']);

    

    // if (!form.valid) {
    //   this._snackBar.open('Please check the required inputs', 'Close', {
    //     duration: 5000,
    //   });
    //   return; // Detener la ejecución si el número de teléfono está vacío
    // }
    if (
      !formValue['name'] ||
      !formValue['middle_name'] ||
      !formValue['email'] ||
      !formValue['phone_number'] ||
      !formValue['custom:address'] ||
      !formValue['custom:ciudad'] ||
      !formValue['custom:passport'] ||
      !formValue['custom:postcode']
    ) {
      this._snackBar.open('Please check the required inputs', 'Close', {
        duration: 5000,
      });
      return;
    }
    else
    {
      this.lambda.getLambdaFunction("lambda", "/crm/contact/updateProfile", {
        queryStringParameters: {
          "name": formValue['name'].trim(),
          "middle_name": formValue['middle_name'].trim(),
          "custom:address": formValue['custom:address'].trim(),
          "custom:ciudad": formValue['custom:ciudad'].trim(),
          "custom:postcode": formValue['custom:postcode'].trim(),
          "custom:country": formValue['custom:country'].trim(),
          "custom:passport": formValue['custom:passport'].trim(),
          "email": formValue['email'].trim(),
          "phone_number": formValue['phone_number'].trim()
        }
      }).then((result: any) => {
        if(result.ok){
          this._snackBar.open('Your profile was updated successfully', 'Close', {
            duration: 5000,
          });
          let back_url = sessionStorage.getItem('back_url');
          if (back_url != null && back_url != '') {
            this.router.navigate([back_url], { replaceUrl: true });
          } else {
            this.goBack();
          }
        }
        else
        {
          this._snackBar.open('Failed:'+result.message, 'Close', {
            duration: 5000,
          });
        }
      

      }).catch((error: any) => {
        this._snackBar.open('Failed to update profile. Please try again.', 'Close', {
              duration: 5000,
            });
            console.error('Error updating profile:', error);
            return;
      })
    }
    // try {
    //   await this.auth.updateUser(formValue);
    //   this._snackBar.open('Your profile was updated successfully', 'Close', {
    //     duration: 5000,
    //   });
    //   this.goBack();
    // } catch (error) {
    //   this._snackBar.open('Failed to update profile. Please try again.', 'Close', {
    //     duration: 5000,
    //   });
    //   console.error('Error updating profile:', error);
    //   return;
    // }
  }
  

  goBack() {
    this.router.navigate(['profile'], { replaceUrl: true });
  }
}
