import { Component, Inject } from '@angular/core';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import {  FormBuilder,  FormControl,  FormGroup,  Validators,} from '@angular/forms';
import { VerificationNumberAttempt } from 'src/models/extra';
import { AWSLambda } from 'src/app/AWSLambda.service';

@Component({
  selector: 'app-verify-number',
  templateUrl: './verify-number.component.html',
  styles: [`h2, p{font-family: "Montserrat", system-ui, sans-serif !important;}`]
})
export class VerifyNumberComponent {

  hasError: boolean = false

  createForm: FormGroup = this.fb.group({
    code: new FormControl('', [Validators.required, Validators.maxLength(6),Validators.minLength(6),Validators.pattern("^[0-9]*$") ])
  })

  constructor(
    public dialog: MatDialogRef<VerifyNumberComponent>, @Inject(MAT_DIALOG_DATA) 
    public data: { id: string, phone_number: string, code: number }, 
    private fb: FormBuilder, 
    private lambda: AWSLambda
  ) { }

  goBack(): void {
    this.dialog.close({ commit: false, verified: false} as VerificationNumberAttempt);
  }

  onVerify(): void {
    const { code } = this.createForm.getRawValue()
    //Check the verification:

    this.lambda.getLambdaFunction("lambda", "/crm/contact/update", {
      queryStringParameters: {  verificationcode: code}
    }).then((response: any) => {
  
      if (response.ok) {
        // Verification successful
        this.hasError = false;
        const output = Object.assign({}, this.data, { commit: true, verified: true }) as VerificationNumberAttempt;
        this.dialog.close(output);
      } else {
        // Verification failed
        this.hasError = true;
        this.createForm.reset();
      }
    }).catch((error: any) => {
      console.error("Error during verification:", error);
      // Handle the error, reset form, and display an error message
      this.hasError = true;
      this.createForm.reset();
    })


    
  }

}
